import { api } from './request.js'

//登录
export function apiAccountLogin(data){
    return api('account/AccountLogin',data);
}

//
export function apiAccountInfo(){
    return api('account/AccountInfo');
}

//账号列表
export function apiFirmList(data){
    return api('account/FirmList',data);
}

//修改密码
export function apiAccountEditPwd(data){
    return api('account/AccountEditPwd',data);
}

//创建新账号
export function apiCreateFirm(data){
    return api('account/CreateFirm',data);
}

//跳转后台管理页面
export function apiLoginAdmin(data){
    return api('account/loginAdmin',data);
}

//添加客户
export function apiCustomSave(data){
    return api('custom/save',data);
}
//跟进
export function apiFollow(data){
    return api('custom/follow',data);
}

//获取客户列表
export function apiCustomList(data){
    return api('custom/list',data);
}

//获取客户详情
export function apiCustomDetail(data){
    return api('custom/detail',data);
}

//获取客户订单列表
export function apiCustomOrderList(data){
    return api('custom/orderList',data);
}
//添加 修改客户订单
export function apiCustomOrderSave(data){
    return api('custom/orderSave',data);
}

//获取客户发票列表
export function apiCustomInvoiceList(data){
    return api('custom/invoiceList',data);
}
//添加 修改客户发票
export function apiCustomInvoiceSave(data){
    return api('custom/invoiceSave',data);
}


//获取交付列表
export function apiDeliverList(data){
    return api('custom/deliverList',data);
}

// //修改交付
export function apiDeliverSave(data){
    return api('custom/deliverSave',data);
}

//交付列表登录
export function apiDeliverLogin(data){
    return api('custom/deliverLogin',data);
}

//设计师列表
export function apiDesignerList(){
    return api('custom/designerList');
}


//账号列表
export function apiAccountList(data){
    return api('custom/accountList',data)
}
//添加修改账号
export function apiAccountSave(data){
    return api('custom/accountSave',data)
}



//模板草稿箱列表
export function apiVersionDraft(){
    return api('custom/versionDraft')
}

//添加到模板库
export function apiVersionTemplateAdd(data){
    return api('custom/versionTemplateAdd',data)
}

//获取模板列表
export function apiVersionTemplate(){
    return api('custom/versionTemplate')
}

//发布模板
export function apiVersionTemplateRelease(data){
    return api('custom/versionTemplateRelease',data)
}

//删除模板
export function apiVersionTemplateDelete(data){
    return api('custom/versionTemplateDelete',data)
}

//获取版本列表
export function apiVersionList(data){
    return api('custom/versionList',data)
}
